import React, { createContext, useEffect, useState } from "react"
import fetch from "isomorphic-fetch"
import Shopify from "shopify-buy"
import ShopifyUnoptimized from "shopify-buy/index.unoptimized.umd"
import * as Contentful from "contentful"

import { smartReplacements } from "../utils/text-properties"

const shopifyClient = Shopify.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
)

const shopifyUnoptimizedClient = ShopifyUnoptimized.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
)

const contentfulClient = Contentful.createClient({
  space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
  accessToken: process.env.GATSBY_CONTENTFUL_CDA_ACCESS_TOKEN,
})

const defaultValues = {
  cart: [],
  isOpen: false,
  initialLoading: false,
  loading: false,
  onOpen: () => { },
  onClose: () => { },
  addVariantToCart: () => { },
  removeLineItem: () => { },
  updateLineItem: () => { },
  shopifyClient,
  contentfulClient,
  products: [],
  checkout: {
    lineItems: [],
  },
}

export const StoreContext = createContext(defaultValues)

const isBrowser = typeof window !== "undefined"
const localStorageKey = "shopify_checkout_id"

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = useState(null)
  const [products, setProducts] = useState(defaultValues.products)
  const [initialLoading, setInitialLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = useState(false)
  const [cache, setCache] = useState({})

  // enhance checkout by Shopify products and Contentful entries
  useEffect(() => {
    /**
     * load product data for given checkout line items
     * @param {Object} checkout
     * @returns {Array}
     */
    const loadProductData = async checkout => {
      const queries = checkout.lineItems.map(lineItem => {
        // check cache
        if (cache[lineItem.variant.id]) {
          return cache[lineItem.variant.id]
        }

        // fetch Shopify product
        const shopifyProduct = shopifyClient.product.fetch(
          lineItem.variant.product.id
        )

        // fetch Shopify product with transformed images, variants and inventories
        const shopifyProductQuery =
          shopifyUnoptimizedClient.graphQLClient.query(root => {
            root.add(
              "node",
              { args: { id: lineItem.variant.product.id } },
              node => {
                node.addInlineFragmentOn("Product", product => {
                  product.add("id")
                  product.addConnection(
                    "images",
                    { args: { first: 10 } },
                    image => {
                      image.add("altText")
                      image.add("transformedSrc", { args: { maxWidth: 300 } })
                    }
                  )
                  product.addConnection(
                    "variants",
                    { args: { first: 100 } },
                    productVariant => {
                      productVariant.add("id")
                      productVariant.add("quantityAvailable")
                    }
                  )
                })
              }
            )
          })
        const shopifyProductWithQuantityAvailable =
          shopifyUnoptimizedClient.graphQLClient
            .send(shopifyProductQuery)
            .then(({ data }) => data.node)

        // fetch Contentful entry
        const contentfulEntry = contentfulClient.getEntries({
          "fields.shopifyProductVariantId": lineItem.variant.id,
          content_type: "product",
          locale: "*",
        })

        // return combined promise
        const promises = Promise.all([
          shopifyProduct,
          shopifyProductWithQuantityAvailable,
          contentfulEntry,
        ])

        // update cache if necessary
        setCache(cache => {
          return {
            ...cache,
            [lineItem.variant.id]: promises,
          }
        })

        return promises
      })

      // return all promises
      return Promise.all(queries)
    }

    if (checkout !== null && !checkout.completedAt) {
      loadProductData(checkout)
        .then(responses => {
          setProducts(() => {
            return responses.map(
              ([product, productWithQuantityAvailable, entry]) => {
                // we enrich the product with
                // - isGiftCard for a static id
                // - transformed images by adding `transformedSrc`
                // - variants by adding `inventoryQuantity`
                const enrichedProduct = {
                  ...product,
                  isGiftCard:
                    product.id ===
                    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2NDk4NDc3MDk3Nzk=",
                  transformedImages:
                    productWithQuantityAvailable.images.edges.map(i => i.node),
                  variants: product.variants.map(variant => {
                    const matchedVariant =
                      productWithQuantityAvailable.variants.edges.find(
                        e => e.node.id === variant.id
                      )
                    return {
                      ...variant,
                      inventoryQuantity: matchedVariant
                        ? matchedVariant.node.quantityAvailable
                        : 0,
                    }
                  }),
                }
                const extensions =
                  entry.items.length > 0 ? entry.items[0].fields : {}
                return { ...enrichedProduct, ...extensions }
              }
            )
          })
        })
        .catch(console.error)
        .finally(() => setInitialLoading(false))
    }
  }, [checkout, cache])

  /**
   * get localized product by Shopify Storefront product ID
   * @param {Array} products
   * @param {String} id
   * @param {String} locale
   * @returns {Object}
   */
  const getLocalizedProduct = (products, id, locale) => {
    const product = products.find(p => p.id === id)
    if (product) {
      // eslint-disable-next-line no-unused-vars
      const { productTitle, productSlug, ...matchedProduct } = product
      return {
        ...matchedProduct,
        handle: product.productSlug
          ? product.productSlug[locale]
          : product.handle,
        title: product.productTitle
          ? smartReplacements(product.productTitle[locale], locale)
          : smartReplacements(product.title, locale),
      }
    }
    return {}
  }

  /**
   * set checkout in state and if browser in local storage
   * @param {Object} checkout
   */
  const setCheckoutItem = checkout => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }
    setCheckout(checkout)
  }

  // load checkout line items
  useEffect(() => {
    const initializeCheckout = async () => {
      setInitialLoading(true)
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (existingCheckoutID && existingCheckoutID !== "null") {
        try {
          // fetch checkout including `lineItems` list
          const existingCheckout = await shopifyClient.checkout.fetch(
            existingCheckoutID
          )

          // if not already completed enhance data
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout)
            setLoading(false)
            return
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null)
        }
      }

      const newCheckout = await shopifyClient.checkout.create()
      setCheckoutItem(newCheckout)
    }

    initializeCheckout()
  }, [])

  /**
   * add variant to cart
   * @param {String} variantId
   * @param {Number} quantity
   * @returns
   */
  const addVariantToCart = async (variantId, quantity) => {
    setLoading(true)

    const checkoutID = checkout.id
    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ]

    const res = await shopifyClient.checkout.addLineItems(
      checkoutID,
      lineItemsToUpdate
    )
    setCheckout(res)
    setLoading(false)
    setDidJustAddToCart(true)
    setTimeout(() => setDidJustAddToCart(false), 3000)
  }

  /**
   * remove line item from cart
   * @param {String} checkoutID
   * @param {String} lineItemID
   * @returns
   */
  const removeLineItem = async (checkoutID, lineItemID) => {
    setLoading(true)

    const res = await shopifyClient.checkout.removeLineItems(checkoutID, [
      lineItemID,
    ])
    setCheckout(res)
    setLoading(false)
  }

  /**
   * update quantity of line item in cart
   * @param {String} checkoutID
   * @param {String} lineItemID
   * @param {Number} quantity
   * @returns
   */
  const updateLineItem = async (checkoutID, lineItemID, quantity) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    const res = await shopifyClient.checkout.updateLineItems(
      checkoutID,
      lineItemsToUpdate
    )
    setCheckout(res)
    setLoading(false)
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        products,
        getLocalizedProduct,
        checkout,
        initialLoading,
        loading,
        didJustAddToCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
