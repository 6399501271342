import React, { useState } from "react"

export const ListOptionsContext = React.createContext()

const ListOptionsProvider = props => {
  const [listOptions, setListOptions] = useState({})

  return (
    <ListOptionsContext.Provider value={{ listOptions, setListOptions }}>
      {props.children}
    </ListOptionsContext.Provider>
  )
}

export default ListOptionsProvider
