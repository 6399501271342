import React, { createContext, useState } from "react"

const defaultValues = {
  content: "",
  isOpen: false,
}

export const DrawerContext = createContext(defaultValues)

export const DrawerProvider = ({ children }) => {
  const [drawerContent, setDrawerContent] = useState(defaultValues.content)
  const [isDrawerOpen, setIsDrawerOpen] = useState(defaultValues.isOpen)

  return (
    <DrawerContext.Provider
      value={{
        ...defaultValues,
        drawerContent,
        setDrawerContent,
        isDrawerOpen,
        setIsDrawerOpen,
      }}
    >
      {children}
    </DrawerContext.Provider>
  )
}
