import React, { createContext, useState } from "react"
import { notificationContent, notificationShown } from "../config/main"

const defaultValues = {
  content: notificationContent,
  isShown: notificationShown,
}

export const NotificationContext = createContext(defaultValues)

export const NotificationProvider = ({ children }) => {
  const [isNotificationShown, setIsNotificationShown] = useState(defaultValues.isShown)

  return (
    <NotificationContext.Provider
      value={{
        ...defaultValues,
        notificationContent,
        isNotificationShown,
        setIsNotificationShown,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}
