module.exports = {
  defaultSlugs: {
    de: {
      blog: "blog",
      cart: "warenkorb",
      product: "produkt",
      search: "suche",
    },
    en: {
      blog: "blog",
      cart: "cart",
      product: "product",
      search: "search",
    },
  },
  // locales: ["de", "en"],
  locales: ["de"],
  mainLocale: "de",
  stickyTop: 160,
  notificationContent: "Aufgepasst: Wir machen eine Pause und versenden wieder ab dem 03.08.2024. Bis bald!",
  notificationShown: false,
  featureFlags: {
    inMainNavigation: {
      "blog": true,
      "ueber-uns": false,
    },
    inFooterMenu: {
      "ueber-uns": true,
    },
    onHomepage: {
      "blog": true,
    },
  },
}
