/**
 * smartly replace typographically incorrect characters
 * @param {String} text
 * @param {String} locale, defaults to "de"
 * @returns String
 */
const smartReplacements = (text, locale = "de") => {
  if (!text) return text
  if (typeof text.replace === "undefined") return text

  if (locale === "de") {
    text = text.replace(/(^|[-\u2014\s(["])'/g, "$1\u201a") // opening singles
    text = text.replace(/'/g, "\u2019") // closing singles & apostrophes
    text = text.replace(/(^|[-\u2014/[(\u2018\s])"/g, "$1\u201e") // opening doubles
    text = text.replace(/"/g, "\u201c") // closing doubles
    text = text.replace(/--/g, "\u2014") // em-dashes
    text = text.replace(/ - /g, " \u2014 ") // em-dashes
    text = text.replace(/^- /gm, "\u2022 ") // bullet (at beginning of lines)
  } else {
    text = text.replace(/(^|[-\u2014\s(["])'/g, "$1\u2018") // opening singles
    text = text.replace(/'/g, "\u2019") // closing singles & apostrophes
    text = text.replace(/(^|[-\u2014/[(\u2018\s])"/g, "$1\u201c") // opening doubles
    text = text.replace(/"/g, "\u201d") // closing doubles
    text = text.replace(/--/g, "\u2014") // em-dashes
    text = text.replace(/ - /g, " \u2014 ") // em-dashes
    text = text.replace(/^- /gm, "\u2022 ") // bullet (at beginning of lines)
  }
  return text
}

/**
 * A string hashing function based on Daniel J. Bernstein’s popular 'times 33' hash algorithm.
 * See: https://github.com/MatthewBarker/hash-string/blob/master/source/hash-string.js
 * See: https://github.com/joakimbeng/short-hash/blob/master/src/index.js
 * @param {String} str
 * @returns
 */
const hashString = str => {
  let hash = 5381
  let index = str.length

  while (index) {
    hash = (hash * 33) ^ str.charCodeAt(--index);
  }

  return (hash >>> 0).toString(16)
}

// required to be used in `/src/config/search.js` as part of `gatsby-node.js`
module.exports = {
  smartReplacements,
  hashString,
}
