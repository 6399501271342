import React from "react"
import { IntlProvider } from "react-intl"
import * as Sentry from "@sentry/react"

import messages from "../locales/translations.json"

import ListOptionsProvider from "./list-options-provider"
import { DrawerProvider } from "./drawer-context"
import { NotificationProvider } from "./notification-context"
import { StoreProvider } from "./store-context"

import config from "../config/main"

const isBrowser = typeof window !== "undefined"

export const PageContext = React.createContext({})

export const wrapPageElement = ({ element, props }) => {
  let { pageContext } = props
  // fallback to main locale
  if (!pageContext.locale) pageContext.locale = config.mainLocale
  let { locale: currentLocale } = pageContext

  // configure Sentry
  Sentry.configureScope(scope => {
    scope.addEventProcessor(event => {
      // eslint-disable-next-line no-unused-vars
      const { user, ...redacted } = event
      return redacted
    })
  })

  // custom intl error handler
  // const intlErrorHandler = err => {
  //   console.info(err.message)
  // }

  // Sentry.ErrorBoundary
  // see: https://docs.sentry.io/platforms/javascript/guides/react/components/errorboundary/
  return (
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <PageContext.Provider value={props.pageContext}>
        <IntlProvider
          locale={currentLocale}
          messages={messages[currentLocale]}
          // onError={e => intlErrorHandler(e)}
        >
          {isBrowser && (
            <StoreProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <ListOptionsProvider>{element}</ListOptionsProvider>
                </DrawerProvider>
              </NotificationProvider>
            </StoreProvider>
          )}
          {!isBrowser && element}
        </IntlProvider>
      </PageContext.Provider>
    </Sentry.ErrorBoundary>
  )
}
